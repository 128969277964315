import { Box, Flex, Text } from "@chakra-ui/react";
import * as React from "react";

const Footer = () => (
	<Flex
		maxW="5xl"
		px={12}
		my={10}
		alignItems="left"
		direction={{ base: "column", md: "row" }}
		justifyContent="space-between"
		mx="auto"
	>
		<Box>
			<Text>Allison McBride — </Text>
			<Text>Program Manager - Overdose Prevention</Text>
		</Box>
		<Box>
			<Text>Contact — </Text>
			<Text as="a" href="mailto:aamcbride20@gmail.com">
				aamcbride20@gmail.com
			</Text>
		</Box>
		<Box>
			<Text>Social — </Text>
			<Text>
				<Text as="a" href="https://twitter.com/allisonamcbride">
					Twitter,{" "}
				</Text>
				<Text
					as="a"
					href="https://www.linkedin.com/in/allison-mcbride-mph-a41b39155/"
				>
					LinkedIn
				</Text>
			</Text>
		</Box>
	</Flex>
);

export default Footer;
