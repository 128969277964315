import * as React from "react";
import { Link as GLink } from "gatsby";
import {
	Box,
	Button,
	chakra,
	CloseButton,
	Flex,
	HStack,
	IconButton,
	Text,
	useDisclosure,
	useColorModeValue,
	VStack,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

function NavBar() {
	const bg = useColorModeValue("white", "gray.800");
	const mobileNav = useDisclosure();

	return (
		<React.Fragment>
			<chakra.header
				bg={bg}
				w="full"
				px={{ base: 2, sm: 4 }}
				py={4}
				shadow="md"
			>
				<Flex alignItems="center" justifyContent="space-between">
					<Text as={GLink} to="/" fontFamily="PT Serif" fontSize="xl">
						Allison McBride, MPH
					</Text>
					<HStack display="flex" alignItems="center" spacing={1}>
						<HStack
							spacing={1}
							mr={1}
							color="brand.500"
							display={{ base: "none", md: "inline-flex" }}
						>
							<Button
								fontFamily="PT Serif"
								fontSize="xl"
								as={GLink}
								to="/"
								variant="ghost"
							>
								Work
							</Button>
							<Button
								fontFamily="PT Serif"
								fontSize="xl"
								as={GLink}
								to="/info"
								variant="ghost"
							>
								Info
							</Button>
						</HStack>
						<Box display={{ base: "inline-flex", md: "none" }}>
							<IconButton
								display={{ base: "flex", md: "none" }}
								aria-label="Open menu"
								fontSize="20px"
								color={useColorModeValue("gray.800", "inherit")}
								variant="ghost"
								icon={<HamburgerIcon />}
								onClick={mobileNav.onOpen}
							/>

							<VStack
								pos="absolute"
								top={0}
								left={0}
								right={0}
								display={mobileNav.isOpen ? "flex" : "none"}
								flexDirection="column"
								p={2}
								pb={4}
								m={2}
								bg={bg}
								spacing={3}
								rounded="sm"
								shadow="sm"
							>
								<CloseButton
									aria-label="Close menu"
									onClick={mobileNav.onClose}
								/>

								<Button as={GLink} to="/" w="full" variant="ghost">
									Work
								</Button>
								<Button as={GLink} to="/info" w="full" variant="ghost">
									Info
								</Button>
							</VStack>
						</Box>
					</HStack>
				</Flex>
			</chakra.header>
		</React.Fragment>
	);
}

export default NavBar;
